@font-face {
  font-family: 'Muli';
  src: local('Muli'), url('./Muli.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Muli';
  src: local('MuliB'), url('./MuliB.ttf') format('truetype');
  font-weight: 700;
  font-style: bold;
}

@font-face {
  font-family: 'Muli';
  src: local('MuliS'), url('./MuliS.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Linotte Regular';
  src: url('linotte.woff2') format('woff2'), url('linotte.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Linotte Semibold';
  src: url('linotte-semibold.woff2') format('woff2'),
    url('linotte-semibold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Linotte Light';
  src: url('linotte-light.woff2') format('woff2'),
    url('linotte-light.woff') format('woff');
  font-display: swap;
}
